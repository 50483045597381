import React from "react";
import { Layout, Menu } from "antd";

import {
  BarsOutlined,
  DashboardOutlined,
  UserOutlined,
  FileImageOutlined,
  // SettingOutlined,
  FileSearchOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

function SiderBar() {
  const { Sider } = Layout;
  return localStorage.getItem("id") ? (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <br />
      <img className="logo" src="./logo.png" alt="Logo" />
      <br />
      <Menu
        className="site-layout-sub-header-background"
        mode="inline"
        defaultSelectedKeys={["Dashboard"]}
      >
        <Menu.Item key="1" icon={<DashboardOutlined />}>
          <Link to="/"> Dashboard </Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<FileImageOutlined />}>
          <Link to="/slider-banner"> Slider-Banner </Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<FileImageOutlined />}>
          <Link to="/pop-up"> Pop-Up </Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<FileSearchOutlined />}>
          <Link to="/dynamic-content"> Dynamic Content </Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<MessageOutlined />}>
          <Link to="/testimonial">Testimonial</Link>
        </Menu.Item>
        <Menu.Item key="6" icon={<BarsOutlined />}>
          <Link to="/category">Category</Link>
        </Menu.Item>
        <Menu.Item key="7" icon={<UserOutlined />}>
          <Link to="/user"> User </Link>
        </Menu.Item>
        <Menu.Item key="8" icon={<FileSearchOutlined />}>
          <Link to="/carrer-posts"> Carrer-Posts </Link>
        </Menu.Item>
        <Menu.Item key="9" icon={<MessageOutlined />}>
          <Link to="/carrer-applieds"> Carrer Applieds</Link>
        </Menu.Item>
        <Menu.Item key="10" icon={<MessageOutlined />}>
          <Link to="/contacts-message"> Contacts Message</Link>
        </Menu.Item>
        {/* <SubMenu key="9" icon={<SettingOutlined />} title="Setting">
          <Menu.Item key="9a" icon={<SettingOutlined />}>
            <Link to="/websiteSetting"> Website Setting</Link>
          </Menu.Item>
        </SubMenu> */}
      </Menu>
    </Sider>
  ) : null;
}

export default SiderBar;
