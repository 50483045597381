/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from "react";
import LayoutFooter from "../inc/LayoutFooter";
import LayoutHeader from "../inc/LayoutHeader";
import { useNavigate, useParams } from "react-router-dom";
import { Layout, Col, Row } from "antd";
import { Button, Card, Form, Input } from "antd";
import { displayMessage, postAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import {
  EDIT_CARRER_POSTS,
  CARRER_POST_DETAIL,
  PROJECT_NAME,
} from "../../constants/api";

function EditCarrerPosts() {
  const { Content } = Layout;
  let history = useNavigate();
  const { id } = useParams();

  const [state, setstate] = useState(null);
  const [loading, setloading] = useState(true);
  const [form] = Form.useForm();

  useLayoutEffect(() => {
    if (state === null) {
      getDetail();
    }
  }, [
    state,
    form.setFieldsValue({
      title: state != null ? state["title"] : "",
      job_type: state != null ? state["job_type"] : "",
      job_location: state != null ? state["job_location"] : "",
      salary: state != null ? state["salary"] : "",
      salary_type: state != null ? state["salary_type"] : "",
      company_name: state != null ? state["company_name"] : "",
      total_position: state != null ? state["total_position"] : "",
    }),
  ]);

  const getDetail = () => {
    const postData = new FormData();
    postData.append("id", id);
    const successFn = function (data) {
      console.log(data);
      setstate(data["data"]);
      setloading(false);
      console.log(state);
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to get detail");
    };
    postAPI(CARRER_POST_DETAIL, postData, successFn, errorFn);
  };

  const createCategory = (values) => {
    console.log(values);

    const data = new FormData();
    data.append("id", state.id);

    if (values.title) {
      data.append("title", values.title);
    }
    if (values.job_type) {
      data.append("job_type", values.job_type);
    }
    if (values.job_location) {
      data.append("job_location", values.job_location);
    }
    if (values.salary) {
      data.append("salary", values.salary);
    }
    if (values.salary_type) {
      data.append("salary_type", values.salary_type);
    }
    if (values.company_name) {
      data.append("company_name", values.company_name);
    }
    if (values.total_position) {
      data.append("total_position", values.total_position);
    }

    const successFn = function (data) {
      console.log(data);
      if (data["status"] === true) {
        history("/carrer-posts");
        displayMessage(SUCCESS_MESSAGE, "Data Update Successfully");
      } else {
        displayMessage(ERROR_MSG_TYPE, data["message"]);
      }
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Update Data");
    };
    postAPI(EDIT_CARRER_POSTS, data, successFn, errorFn);
  };
  return (
    <>
      <Layout>
        <LayoutHeader />
        <Content>
          <Card loadin={loading} title="Edit Carrer-Post" bordered={false}>
            <Form onFinish={createCategory} form={form} layout="vertical">
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="title"
                      label="Title"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Title"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="job_type"
                      label="Job Type "
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Ex: Full-Time, Part-Time"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="job_location"
                      label="Job Location"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Job Location"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="salary"
                      label="salary"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Ex: 10K-20K"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="salary_type"
                      label="Salary Type"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Ex: Year or Month"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="company_name"
                      label="Company Name "
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder={"Ex: " + PROJECT_NAME}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row spceber>

                  <Col span={12}>
                    <Form.Item
                      name="total_position"
                      label="Total Position"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Ex: 1"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Row spceber>
                <Col span={24}>
                  <Form.Item style={{ float: "right" }} label="" colon={false}>
                    <Button block htmlType="submit">
                      Submit Carrer-Post
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Content>
        <LayoutFooter />
      </Layout>
    </>
  );
}
export default EditCarrerPosts;
