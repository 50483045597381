import React, { Component } from 'react'
import { Layout } from 'antd';
const { Footer } = Layout;


class LayoutFooter extends Component {
    render() {
        return (
                <Footer style={{ textAlign: 'right', backgroundColor:'White', marginTop:'20px', fontWeight:'bold', height:'60px', color:'#1890ff', }}><span style={{cursor:'pointer' }}>Shourya ©2021 </span>
                <p style={{float:'left', fontWeight:'initial', color:'black'}}>Version: 18.12.1</p>
                </Footer>
        )
    }
}

export default LayoutFooter;