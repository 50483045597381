import React, { useState } from "react";
import LayoutFooter from "../inc/LayoutFooter";
import LayoutHeader from "../inc/LayoutHeader";
import { Layout, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { Button, Card, Form, Input } from "antd";
import { displayMessage, postAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import { CREATE_TESTIMONIAL } from "../../constants/api";

function AddTestimonial() {
  const { Content } = Layout;
  let history = useNavigate();
  const [selectedUserFile, setSelectedUserFile] = useState(null);
  const [selectedUserFileValue, setSelectedUserFileValue] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileValue, setSelectedFileValue] = useState(null);
  const imageExtensions = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
    "image/bmp",
  ];
  const onFileChange = (e) => {
    const files = e?.target?.files;
    let filePath = files[0];
    console.log(files);
    if (imageExtensions.includes(filePath?.type)) {
      setSelectedFile({ selectedFile: filePath });
      setSelectedFileValue(filePath.name);
    } else {
      setSelectedFile({});
      displayMessage(ERROR_MSG_TYPE, "not supported images");
    }
  };
  const onUserFileChange = (e) => {
    const files = e?.target?.files;
    let filePath = files[0];
    console.log(files);
    if (imageExtensions.includes(filePath?.type)) {
      setSelectedUserFile({ selectedUserFile: filePath });
      setSelectedUserFileValue(filePath.name);
    } else {
      setSelectedUserFile({});
      displayMessage(ERROR_MSG_TYPE, "not supported images");
    }
  };
  const create = (values) => {
    console.log(values);

    const data = new FormData();
    data.append("name", values.name);
    data.append("company_name", values.company_name);
    data.append("description", values.description);
    data.append("link", values.link);
    if (selectedUserFile) {
      data.append("user_image", selectedUserFile.selectedUserFile);
    }
    if (selectedFile) {
      data.append("company_logo", selectedFile.selectedFile);
    }
    const successFn = function (data) {
      history("/testimonial");
      displayMessage(SUCCESS_MESSAGE, "Data Saved Successfully");
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Save Data");
    };
    postAPI(CREATE_TESTIMONIAL, data, successFn, errorFn);
  };
  return (
    <>
      <Layout>
        <LayoutHeader />
        <Content>
          <Card title="Add Testimonial" bordered={false}>
            <Form onFinish={create} layout="vertical">
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="name"
                      label="User Name"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Name"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="company_name"
                      label="Comany Name"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Company Name"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="description"
                      label="Testimonial Description"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Description"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="link"
                      label="Testimonial Link"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Link"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      type="file"
                      name="company_logo"
                      label="Company Image"
                      rules={[{ required: true }]}
                      style={{ marginLeft: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type="file"
                        className="filetype"
                        defaultValue={selectedFileValue}
                        id="myImage"
                        onChange={(e) => onFileChange(e)}
                        name="myImage"
                        placeholder="Company Logo"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      type="file"
                      name="user_image"
                      label="User Image"
                      rules={[{ required: true }]}
                      style={{ marginLeft: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type="file"
                        className="filetype"
                        defaultValue={selectedUserFileValue}
                        id="userImage"
                        onChange={(e) => onUserFileChange(e)}
                        name="userImage"
                        placeholder="User Image"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Row spceber>
                <Col span={24}>
                  <Form.Item style={{ float: "right" }} label="" colon={false}>
                    <Button block htmlType="submit">
                      Submit Testimonial
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Content>
        <LayoutFooter />
      </Layout>
    </>
  );
}
export default AddTestimonial;
