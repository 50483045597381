/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from "react";
import { displayMessage, postAPI, getAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import { ALL_CARRER_POSTS, DELETE_CARRER_POSTS } from "../../constants/api";
import { Space, Button, Table } from "antd";
import { useNavigate } from "react-router-dom";
// import  { ColumnsType } from 'antd/es/table';
import LayoutHeader from "../inc/LayoutHeader";
import LayoutFooter from "../inc/LayoutFooter";
import { Layout } from "antd";
import { Card } from "antd";

function CarrerPostsTable() {
  const { Content } = Layout;
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(true);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (state.length === 0) {
      getData();
    }
  }, [state, navigate]);
  const getData = () => {
    const successFn = function (data) {
      console.log(data);
      setloading(false);
      setstate(
        data["data"].map((row) => ({
          ID: row.id,
          title: row.title,
          job_type: row.job_type,
          job_location: row.job_location,
          salary: row.salary,
          salary_type: row.salary_type,
          company_name: row.company_name,
          total_position: row.total_position,
          Action: (
            <Space>
              <Button
                onClick={() => {
                  navigate("/carrer-posts/edit/" + row.id);
                }}
                block
              >
                Edit
              </Button>
              {row.id !== 1 ? (
                <Button onClick={(e) => deleteUser(row.id)} danger>
                  Delete
                </Button>
              ) : null}
            </Space>
          ),
        }))
      );
      console.log(state);
    };
     const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Get Data");
    };
    getAPI(ALL_CARRER_POSTS, successFn, errorFn, {});
  };
  const deleteUser = (values) => {
    console.log(values);
    let data = {
      id: values,
    };
    const successFn = function (data) {
      getData();
      displayMessage(SUCCESS_MESSAGE, "Data Delete Successfully");
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Delete Data");
    };
    postAPI(DELETE_CARRER_POSTS, data, successFn, errorFn);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "ID",
      width: 50,
    },
    {
      title: "Title",
      dataIndex: "title",
      width: 150,
    },
    {
      title: "Job Type",
      dataIndex: "job_type",
      width: 50,
    },
    {
      title: "Job Location",
      dataIndex: "job_location",
      width: 50,
    },
    {
      title: "Salary",
      dataIndex: "salary",
      width: 50,
    },
    {
      title: "Salary Type",
      dataIndex: "salary_type",
      width: 50,
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      width: 50,
    },
    {
      title: "Total Position",
      dataIndex: "total_position",
      width: 50,
    },

    {
      title: "Action",
      dataIndex: "Action",
      width: 150,
    },
  ];
  return (
    <>
      <Layout>
        <LayoutHeader />
        <Content>
          <Card
            title="Manage Carrer-Posts"
            extra={
              <Button
                onClick={() => {
                  navigate("/carrer-posts/add");
                }}
                block
              >
                Add Carrer-Posts
              </Button>
            }
            bordered={true}
          >
            <Table
              loading={loading ? true : false}
              columns={columns}
              dataSource={state}
              pagination={{ pageSize: 10 }}
              // scroll={{ y: 240 }}
            />
          </Card>
        </Content>
        <LayoutFooter />
      </Layout>
    </>
  );
}
export default CarrerPostsTable;
