/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from "react";
import { displayMessage, postAPI, getAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import { ALL_POPUP, DELETE_POPUP, IMAGE_POPUP_URL } from "../../constants/api";
import { Space, Button, Table } from "antd";
import { useNavigate } from "react-router-dom";
// import  { ColumnsType } from 'antd/es/table';
import LayoutHeader from "../inc/LayoutHeader";
import LayoutFooter from "../inc/LayoutFooter";
import { Layout } from "antd";
import { Card } from "antd";
import { Image } from "antd";

function PopupTabel() {
  const { Content } = Layout;
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(true);
  const navigate = useNavigate();
  useLayoutEffect(() => {
    if (state.length === 0) {
      getPopup();
    }
  }, [state]);
  const getPopup = () => {
    const successFn = function (data) {
      console.log(data);
      setloading(false);
      setstate(
        data["data"].map((row) => ({
          Link: row.link,
          Image: <Image width={50} src={IMAGE_POPUP_URL + row.image} />,
          ID: row.id,
          Action: (
            <Space>
              <Button onClick={(e) => deletePopup(row.id)} danger>
                Delete
              </Button>
            </Space>
          ),
        }))
      );
      console.log(state);
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Get Data");
    };
    getAPI(ALL_POPUP, successFn, errorFn, {});
  };

  const deletePopup = (values) => {
    console.log(values);
    let data = {
      id: values,
    };
    const successFn = function (data) {
      getPopup();
      displayMessage(SUCCESS_MESSAGE, "Data Delete Successfully");
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Delete Data");
    };
    postAPI(DELETE_POPUP, data, successFn, errorFn);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "ID",
      width: 50,
    },
    {
      title: "Link",
      dataIndex: "Link",
      width: 150,
    },
    {
      title: "Image",
      dataIndex: "Image",
      width: 150,
    },
    {
      title: "Action",
      dataIndex: "Action",
      width: 150,
    },
  ];
  return (
    <>
      <Layout>
        <LayoutHeader />
        <Content>
          <Card
            title="Manage Pop-Up"
            extra={
              <Button
                onClick={() => {
                  navigate("/pop-up/add");
                }}
                block
              >
                Add Pop-Up
              </Button>
            }
            bordered={true}
          >
            <Table
              loading={loading}
              columns={columns}
              dataSource={state}
              pagination={{ pageSize: 10 }}
              // scroll={{ y: 240 }}
            />
          </Card>
        </Content>
        <LayoutFooter />
      </Layout>
    </>
  );
}
export default PopupTabel;
