/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect } from "react";
import LayoutFooter from "../inc/LayoutFooter";
import LayoutHeader from "../inc/LayoutHeader";
import { useNavigate, useParams } from "react-router-dom";
import { Layout, Col, Row, Select, Image } from "antd";
import { Button, Upload, Card, Form, Input } from "antd";
import { displayMessage, postAPI, getAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import JoditEditor from "jodit-react";
import {
  DYNAMIC_CONTENT_BY_SLUG,
  ALL_CATEGORY,
  EDIT_DYNAMIC_CONTENT,
  BLOG_BASE_URL,
} from "../../constants/api";
import { UploadOutlined } from "@ant-design/icons";
// export default class Addcategories extends Component {
function EditDynamicContent() {
  const { Content } = Layout;
  const { slug } = useParams();
  const [selectedCategory, setSelectCategory] = useState("");
  const [description, setdescription] = useState("");
  const [state, setstate] = useState(null);
  const [category, setCategory] = useState([]);
  const [loading, setloading] = useState(true);
  const [form] = Form.useForm();

  let history = useNavigate();

  useLayoutEffect(() => {
    if (state === null) {
      getDetail();
    }
    if (category.length === 0) {
      getCatgory();
    }
  }, [
    state,
    category,
    form.setFieldsValue({
      meta_title: state != null ? state["meta_title"] : "",
      meta_description: state != null ? state["meta_description"] : "",
      meta_keyword: state != null ? state["meta_keyword"] : "",
      title: state != null ? state["title"] : "",
      description: state != null ? state["description"] : "",
      slug: state != null ? state["slug"] : "",
      product_type:
        state != null
          ? state["isBlog"] === "1" || state["isBlog"] === 1
            ? "Work"
            : "Blog"
          : "",
    }),
  ]);

  const getCatgory = () => {
    setloading(true);
    const successFn = function (data) {
      console.log(data);

      if (data["status"] === true) {
        setCategory(data["data"]);
        setloading(false);
      } else {
        setCategory([]);
        setloading(false);
      }
    };
    const errorFn = function () {
      setCategory([]);
    };
    getAPI(ALL_CATEGORY, successFn, errorFn, {});
  };

  const getDetail = () => {
    const postData = new FormData();
    postData.append("slug", slug);
    const successFn = function (data) {
      console.log(data);
      if (data["status"] === true) {
        setstate(data["data"]);
        setSelectCategory(
          data["data"]["category"] != null
            ? data["data"]["category"]["title"]
            : ""
        );
        setdescription(data["data"]["description"]);
        setloading(false);
        console.log(state);
      } else {
        setstate(null);
        setloading(false);
      }
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to get detail");
    };
    postAPI(DYNAMIC_CONTENT_BY_SLUG, postData, successFn, errorFn);
  };

  const updateDynamicContent = (values) => {
    console.log(values);

    const data = new FormData();
    data.append("id", state.id);
    if (values.category_id) {
      data.append("category_id", values.category_id);
    }
    if (values.meta_title) {
      data.append("meta_title", values.meta_title);
    }
    if (values.meta_description) {
      data.append("meta_description", values.meta_description);
    }
    if (values.meta_keyword) {
      data.append("meta_keyword", values.meta_keyword);
    }
    if (values.slug) {
      data.append("slug", values.slug);
    }
    if (values.title) {
      data.append("title", values.title);
    }
    if (values.description) {
      data.append("description", values.description);
    }
    data.append(
      "isBlog",
      values.product_type === "Work"
        ? "1"
        : values.product_type === "Blog"
        ? "0"
        : values.product_type
    );

    console.log("ImageLength" + values.images.length);
    if (values.images && values.images.length > 0) {
      values.images.forEach((image) => {
        data.append("images[]", image.originFileObj);
      });
    }

    const successFn = function (data) {
      history("/dynamic-content");
      displayMessage(SUCCESS_MESSAGE, "Data Saved Successfully");
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Save Data");
    };
    postAPI(EDIT_DYNAMIC_CONTENT, data, successFn, errorFn);
  };
  return (
    <>
      <Layout>
        <LayoutHeader />
        <Content>
          <Card loading={loading} title="Edit Dynamic-Content" bordered={false}>
            <Form onFinish={updateDynamicContent} form={form} layout="vertical">
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="category_id"
                      label="Category"
                      rules={[{ required: false }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Select
                        defaultValue={selectedCategory}
                        placeholder="Select Content For"
                      >
                        {category.map((row) => (
                          <Select.Option key={row.id} value={row.id}>
                            {row.title}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="title"
                      label="Title"
                      rules={[{ required: false }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Title"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="meta_title"
                      label="Meta Title"
                      rules={[{ required: false }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Meta Title"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="meta_keyword"
                      label="Meta Keyword"
                      rules={[{ required: false }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Meta Keyword"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="meta_description"
                      label="Meta Description"
                      rules={[{ required: false }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Meta Description"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="slug"
                      label="Slug"
                      rules={[{ required: false }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Slug"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="product_type"
                      label="Product Type"
                      rules={[{ required: false }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Select placeholder="Select Content Is">
                        <Select.Option value={"1"}>Work</Select.Option>
                        <Select.Option value={"0"}>Blog</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="images"
                      label="Select Images"
                      valuePropName="fileList"
                      style={{ marginRight: "5px" }}
                      getValueFromEvent={(e) => {
                        if (Array.isArray(e)) {
                          return e;
                        }
                        return e && e.fileList;
                      }}
                      initialValue={[]}
                    >
                      <Upload
                        name="images"
                        listType="picture"
                        multiple={true}
                        beforeUpload={() => false} // Prevent file upload immediately
                      >
                        <Button icon={<UploadOutlined />}>Select Images</Button>
                      </Upload>
                    </Form.Item>
                    <hr />
                    {state != null
                      ? state.images.map((imageRow) => (
                          <Image
                            width={100}
                            src={BLOG_BASE_URL + imageRow.image}
                          />
                        ))
                      : null}
                  </Col>
                </Row>
              </Form.Item>

              <Row spceber>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    // label="description"
                    rules={[{ required: false }]}
                    style={{ marginRight: "5px" }}
                  >
                    <JoditEditor
                      value={description}
                      placeholder="Just brief the your product"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row spceber>
                <Col span={24}>
                  <Form.Item style={{ float: "right" }} label="" colon={false}>
                    <Button block htmlType="submit">
                      Update Dynamic-Content
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Content>
        <LayoutFooter />
      </Layout>
    </>
  );
}
export default EditDynamicContent;
