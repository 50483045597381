/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from "react";
import { getAPI } from "../../utils/common";
import { ALL_CONTACTS } from "../../constants/api";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import LayoutHeader from "../inc/LayoutHeader";
import LayoutFooter from "../inc/LayoutFooter";
import { Layout } from "antd";
import { Card } from "antd";
import { displayMessage } from "../../utils/common";
import { ERROR_MSG_TYPE } from "../../constants/messages";

function ContactsTable() {
  const { Content } = Layout;
  const navigate = useNavigate();
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(true);

  useLayoutEffect(() => {
    if (state.length === 0) {
      getUser();
    }
  }, [state, navigate]);
  const getUser = () => {
    const successFn = function (data) {
      console.log(data);
      setloading(false);
      setstate(
        data["data"].map((row) => ({
          name: row.name,
          email: row.email,
          phone: row.phone,
          whatsapp_number: row.whatsapp_number,
          subject: row.subject,
          interested_in: row.interested_in,
          message: row.message,
          date: row.created_at,
        }))
      );
      console.log(state);
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Get Data");
    };
    getAPI(ALL_CONTACTS, successFn, errorFn, {});
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 50,
    },

    {
      title: "Phone",
      dataIndex: "phone",
      width: 150,
    },
    {
      title: "Whatsapp Number",
      dataIndex: "whatsapp_number",
      width: 150,
    },
    {
      title: "Interested-In",
      dataIndex: "interested_in",
      width: 150,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      width: 150,
    },
    {
      title: "Message",
      dataIndex: "message",
      width: 150,
    },
    {
      title: "Date",
      dataIndex: "date",
      width: 150,
    },
  ];
  return (
    <>
      <Layout>
        <LayoutHeader />
        <Content>
          <Card title="Contact Message" bordered={true}>
            <Table
              loading={loading ? true : false}
              columns={columns}
              dataSource={state}
              pagination={{ pageSize: 10 }}
              // scroll={{ y: 240 }}
            />
          </Card>
        </Content>
        <LayoutFooter />
      </Layout>
    </>
  );
}
export default ContactsTable;
