/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from "react";
import {
  Card,
  Input,
  Form,
  Layout,
  Button,
  Image,
  Col,
  Row,
  Select,
} from "antd";
import { displayMessage, postAPI, getAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import { CREATE_SETTING, SETTING, IMAGE_BASE_URL } from "../../constants/api";
import LayoutHeader from "../inc/LayoutHeader";
import LayoutFooter from "../inc/LayoutFooter";
// import JoditEditor from "jodit-react";

function WebsiteSetting() {
  const { Content } = Layout;
  var [setting, setSetting] = useState(null);
  const [form] = Form.useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const [companyLogo, setCompanyLogo] = useState("");
  const [selectedFileValue, setSelectedFileValue] = useState(null);
  const imageExtensions = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
    "image/bmp",
  ];
  const onFileChange = (e) => {
    const files = e?.target?.files;
    let filePath = files[0];
    console.log(files);
    if (imageExtensions.includes(filePath?.type)) {
      setSelectedFile({ selectedFile: filePath });
      setSelectedFileValue(filePath.name);
    } else {
      setSelectedFile({});
      displayMessage(ERROR_MSG_TYPE, "not supported images");
    }
  };
  useLayoutEffect(() => {
    getSetting();
  }, [
    form.setFieldsValue({
      companyName: setting != null ? setting["companyName"] : "",
      companyDescription: setting != null ? setting["companyDescription"] : "",
      // companyLogo: setting != null ? setting["companyLogo"] : "",
      companyPhone: setting != null ? setting["companyPhone"] : "",
      companyEmail: setting != null ? setting["companyEmail"] : "",
      companyAddress: setting != null ? setting["companyAddress"] : "",
      companyGST: setting != null ? setting["companyGST"] : "",
      companyPanCard: setting != null ? setting["companyPanCard"] : "",
      companyFSSAI: setting != null ? setting["companyFSSAI"] : "",
      // Order Setting
      minimumOrderAmount: setting != null ? setting["minimumOrderAmount"] : "",
      deliveryChargeFreeOn:
        setting != null ? setting["deliveryChargeFreeOn"] : "",
      deliveryCharge: setting != null ? setting["deliveryCharge"] : "",
      plateformCharge: setting != null ? setting["plateformCharge"] : "",
      orderSeries: setting != null ? setting["orderSeries"] : "",
      invoiceSeries: setting != null ? setting["invoiceSeries"] : "",
      // Paytm Payment Getway Setting
      paytmAppSecritKey: setting != null ? setting["paytmAppSecritKey"] : "",
      paytmAppID: setting != null ? setting["paytmAppID"] : "",
      paytmStatus:
        setting != null
          ? setting["paytmStatus"] === true
            ? "Active"
            : "DeActive"
          : "",
      codStatus:
        setting != null
          ? setting["codStatus"] === true
            ? "Active"
            : "DeActive"
          : "",
    }),
  ]);

  const getSetting = () => {
    const successFn = function (data) {
      console.log(data);
      setSetting(data["data"]);
      setCompanyLogo(
        data["result"] !== false ? data["data"]["companyLogo"] : ""
      );
    };
     const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Get Data");
    };
    getAPI(SETTING, successFn, errorFn, {});
  };

  const createSetting = (values) => {
    console.log(values);
    const data = new FormData();
    // Genral Setting
    data.append("companyName", values.companyName);
    data.append("companyDescription", values.companyDescription);
    if (selectedFile) {
      data.append("companyLogo", selectedFile.selectedFile);
    }
    data.append("companyPhone", values.companyPhone);
    data.append("companyEmail", values.companyEmail);
    data.append("companyAddress", values.companyAddress);
    data.append("companyGST", values.companyGST);
    data.append("companyPanCard", values.companyPanCard);
    data.append("companyFSSAI", values.companyFSSAI);
    // Order Setting
    data.append("minimumOrderAmount", values.minimumOrderAmount);
    data.append("deliveryChargeFreeOn", values.deliveryChargeFreeOn);
    data.append("deliveryCharge", values.deliveryCharge);
    data.append("plateformCharge", values.plateformCharge);
    data.append("orderSeries", values.orderSeries);
    data.append("invoiceSeries", values.invoiceSeries);
    // Paytm Payment Getway Setting
    data.append("paytmAppSecritKey", values.paytmAppSecritKey);
    data.append("paytmAppID", values.paytmAppID);
    data.append("paytmStatus", values.paytmStatus === "Active" ? true : false);
    data.append("codStatus", values.codStatus === "Active" ? true : false);
    const successFn = function (data) {
      displayMessage(SUCCESS_MESSAGE, "Data Saved Successfully");
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Save Data");
    };
    postAPI(CREATE_SETTING, data, successFn, errorFn);
  };
  return (
    <Layout>
      <LayoutHeader />
      <Content style={{ margin: "24px 16px 0" }}>
        <div className="site-card-wrapper">
          <Card
            style={{ backgroundColor: "white", marginTop: "20px" }}
            title="Website Setting"
            bordered={true}
          >
            <Form onFinish={createSetting} form={form} layout="vertical">
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="companyName"
                      label="Company Name"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Company Name"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      defaultValue={
                        setting != null ? setting["companyName"] : ""
                      }
                      name="companyDescription"
                      label="Company Description"
                      rules={[{ required: true }]}
                      style={{ marginLeft: "5px" }}
                    >
                      <Input
                        defaultValue={
                          setting != null ? setting["companyName"] : ""
                        }
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Company Description"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      type="file"
                      name="companyLogo"
                      label="Company Logo"
                      rules={[{ required: false }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type="file"
                        className="filetype"
                        initialvalues={selectedFileValue}
                        id="myImage"
                        onChange={(e) => onFileChange(e)}
                        name="myImage"
                        placeholder="Company Logo"
                      />
                      {companyLogo !== "" ? (
                        <Image width={100} src={IMAGE_BASE_URL + companyLogo} />
                      ) : null}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="companyPhone"
                      label="Company Phone"
                      rules={[{ required: true }]}
                      style={{ marginLeft: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Company Phone"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="companyEmail"
                      label="Company Email"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Company Email"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="companyAddress"
                      label="Company Address"
                      rules={[{ required: true }]}
                      style={{ marginLeft: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Company Address"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="companyGST"
                      label="Company GST NO."
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Company GST"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="companyPanCard"
                      label="Company Pan-Card"
                      rules={[{ required: true }]}
                      style={{ marginLeft: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Company Pan-Card"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="companyFSSAI"
                      label="Company FASSI"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Company FSSAI"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="minimumOrderAmount"
                      label="Minimum Order Amt"
                      rules={[{ required: true }]}
                      style={{ marginLeft: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Minimum Order Amount"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="deliveryChargeFreeOn"
                      label="Delivery Charge Free On"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Delivery Charge Free On"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="deliveryCharge"
                      label="Delivery Charge"
                      rules={[{ required: true }]}
                      style={{ marginLeft: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Delivery Charge"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="plateformCharge"
                      label="Plateform Charge"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Plateform Charge"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="orderSeries"
                      label="Order Series"
                      rules={[{ required: true }]}
                      style={{ marginLeft: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Order Series"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="invoiceSeries"
                      label="Invoice Series"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Invoice Series"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="paytmAppSecritKey"
                      label="Paytm App Secret-Key"
                      rules={[{ required: true }]}
                      style={{ marginLeft: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Paytm App Secret Key"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="paytmAppID"
                      label="Paytm App ID"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Paytm App ID"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="paytmStatus"
                      label="Paytm ON/OFF"
                      rules={[{ required: true }]}
                      style={{ marginLeft: "5px" }}
                    >
                      <Select placeholder="Paytm Status">
                        <Select.Option value="Active">Active</Select.Option>
                        <Select.Option value="DeActive">DeActive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      label="COD ON/OFF"
                      name="codStatus"
                      style={{ marginRight: "5px" }}
                    >
                      <Select placeholder="COD Status">
                        <Select.Option value="Active">Active</Select.Option>
                        <Select.Option value="DeActive">DeActive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Row spceber>
                <Col span={24}>
                  <Form.Item style={{ float: "right" }} label="" colon={false}>
                    <Button block htmlType="submit">
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            {/* <Form onFinish={createFrontendSetting}>
              <Form.Item
                label="Banner Title"
                name="banner_title"
                rules={[
                  {
                    required: true,
                    message: "Please input your banner title!",
                  },
                ]}
              >
                <Input placeholder="Banner Title* " />
              </Form.Item>
              <Form.Item
                label="Banner Sub Title"
                name="banner_sub_title"
                rules={[
                  {
                    required: true,
                    message: "Please input your banner subtitle!",
                  },
                ]}
              >
                <Input placeholder="Banner Sub Title* " />
              </Form.Item>
              <Form.Item
                label="About Us"
                name="about_us"
                rules={[
                  { required: true, message: "Please fill your about us!" },
                ]}
              >
                <JoditEditor placeholder="About Us* " />
              </Form.Item>
              <Form.Item
                label="Terms & Condition"
                name="terms_and_condition"
                rules={[
                  {
                    required: true,
                    message: "Please fill your terms and condition!",
                  },
                ]}
              >
                <JoditEditor placeholder="Terms And Condition* " />
              </Form.Item>
              <Form.Item
                label="Privacy Policy"
                name="privacy_policy"
                rules={[
                  {
                    required: true,
                    message: "Please fill your privacy_policy!",
                  },
                ]}
              >
                <JoditEditor placeholder="Privacy Policy* " />
              </Form.Item>
              <Form.Item>
                <button
                  style={{ float: "right" }}
                  className="ant-btn"
                  type="submit"
                >
                  Update Setting
                </button>
              </Form.Item>
            </Form> */}
          </Card>
        </div>
      </Content>
      <LayoutFooter />
    </Layout>
  );
}
export default WebsiteSetting;
