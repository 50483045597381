/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from "react";
import { displayMessage, postAPI, getAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import { USER, DELETE_USER } from "../../constants/api";
import { Space, Button, Table } from "antd";
import { useNavigate } from "react-router-dom";
// import  { ColumnsType } from 'antd/es/table';
import LayoutHeader from "../inc/LayoutHeader";
import LayoutFooter from "../inc/LayoutFooter";
import { Layout } from "antd";
import { Card } from "antd";

function UserTable() {
  const { Content } = Layout;
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(true);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (state.length === 0) {
      getUser();
    }
  }, [state, navigate]);
  const getUser = () => {
    const successFn = function (data) {
      console.log(data);
      setloading(false);
      setstate(
        data["data"].map((row) => ({
          ID: row.id,
          Name: row.name,
          Email: row.email,
          Action: (
            <Space>
              <Button
                onClick={() => {
                  navigate("/user/edit/" + row.id);
                }}
                block
              >
                Edit
              </Button>
              {row.id !== 1 ? (
                <Button onClick={(e) => deleteUser(row.id)} danger>
                  Delete
                </Button>
              ) : null}
            </Space>
          ),
        }))
      );
      console.log(state);
    };
     const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Get Data");
    };
    getAPI(USER, successFn, errorFn, {});
  };
  const deleteUser = (values) => {
    console.log(values);
    let data = {
      id: values,
    };
    const successFn = function (data) {
      getUser();
      displayMessage(SUCCESS_MESSAGE, "Data Delete Successfully");
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Delete Data");
    };
    postAPI(DELETE_USER, data, successFn, errorFn);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "ID",
      width: 50,
    },
    {
      title: "Name",
      dataIndex: "Name",
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "Email",
      width: 50,
    },

    {
      title: "Action",
      dataIndex: "Action",
      width: 150,
    },
  ];
  return (
    <>
      <Layout>
        <LayoutHeader />
        <Content>
          <Card
            title="Manage User"
            extra={
              <Button
                onClick={() => {
                  navigate("/user/add");
                }}
                block
              >
                Add User
              </Button>
            }
            bordered={true}
          >
            <Table
              loading={loading ? true : false}
              columns={columns}
              dataSource={state}
              pagination={{ pageSize: 10 }}
              // scroll={{ y: 240 }}
            />
          </Card>
        </Content>
        <LayoutFooter />
      </Layout>
    </>
  );
}
export default UserTable;
