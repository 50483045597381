/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from "react";
import { displayMessage, postAPI, getAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import {
  ALL_DYNAMIC_CONTENT,
  DELETE_DYNAMIC_CONTENT,
  BLOG_BASE_URL,
} from "../../constants/api";
import { Space, Button, Table, Form, Select } from "antd";
import { useNavigate } from "react-router-dom";
// import  { ColumnsType } from 'antd/es/table';
import LayoutHeader from "../inc/LayoutHeader";
import LayoutFooter from "../inc/LayoutFooter";
import { Layout } from "antd";
import { Card } from "antd";
import { Image } from "antd";

function DynamicContentTabel() {
  const { Content } = Layout;
  const [type, setType] = useState("1");
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(true);
  const navigate = useNavigate();
  useLayoutEffect(() => {
    if (state.length === 0) {
      getDynamicContent(type);
    }
  }, [state, type, navigate]);

  const onChangeType = (values) => {
    console.log(values);
    setType(values);
    getDynamicContent(values);
  };

  const getDynamicContent = (values) => {
    const successFn = function (data) {
      console.log(data);
      if (data["status"] === true) {
        setstate(
          data["data"].map((row) => ({
            Title: row.title,
            Type: row.isBlog === 1 ? "Work" : "Blog",
            Category: row.category != null ? row.category.title : "Unknow",
            Image: row.images.map((imageRow) => (
              <Image width={50} src={BLOG_BASE_URL + imageRow.image} />
            )),
            ID: row.id,
            Action: (
              <Space>
                <Button
                  onClick={() => {
                    navigate("/dynamic-content/edit/" + row.slug);
                  }}
                  block
                >
                  Edit
                </Button>
                <Button onClick={(e) => deleteData(row.id)} danger>
                  Delete
                </Button>
              </Space>
            ),
          }))
        );
        setloading(false);
      } else {
        setstate([]);
        setloading(false);
      }
    };
    const errorFn = function () {
      setstate([]);
      setloading(false);
    };
    getAPI(ALL_DYNAMIC_CONTENT + values, successFn, errorFn, {});
  };
  const deleteData = (values) => {
    console.log(values);
    let data = {
      id: values,
    };
    const successFn = function (data) {
      getDynamicContent(type);
      displayMessage(SUCCESS_MESSAGE, "Data Delete Successfully");
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Delete Data");
    };
    postAPI(DELETE_DYNAMIC_CONTENT, data, successFn, errorFn);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "ID",
      width: 50,
    },
    {
      title: "Title",
      dataIndex: "Title",
      width: 150,
    },
    {
      title: "Category",
      dataIndex: "Category",
      width: 150,
    },
    {
      title: "Type",
      dataIndex: "Type",
      width: 150,
    },
    {
      title: "Image",
      dataIndex: "Image",
      width: 150,
    },
    {
      title: "Action",
      dataIndex: "Action",
      width: 150,
    },
  ];
  return (
    <>
      <Layout>
        <LayoutHeader />
        <Content>
          <Card
            title="Manage Dynamic Content"
            extra={
              <Button
                onClick={() => {
                  navigate("/dynamic-content/add");
                }}
                block
              >
                Add Dynamic Content
              </Button>
            }
            bordered={true}
          >
            <Form layout="vertical">
              <Form.Item
                name="product_type"
                label="Product Type"
                rules={[{ required: true }]}
                defaultValue={"Work"}
              >
                <Select
                  defaultValue={"Work"}
                  placeholder="Select Content Is"
                  onChange={onChangeType}
                >
                  <Select.Option key={1} value={"1"}>
                    Work
                  </Select.Option>
                  <Select.Option key={0} value={"0"}>
                    Blog
                  </Select.Option>
                </Select>
              </Form.Item>
            </Form>
            <Table
              loading={loading}
              columns={columns}
              dataSource={state}
              pagination={{ pageSize: 10 }}
              // scroll={{ y: 240 }}
            />
          </Card>
        </Content>
        <LayoutFooter />
      </Layout>
    </>
  );
}
export default DynamicContentTabel;
