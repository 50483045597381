import React, { useLayoutEffect, useState } from "react";
import LayoutFooter from "../inc/LayoutFooter";
import LayoutHeader from "../inc/LayoutHeader";
import { Layout, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";

import { Button, Card, Form, Input } from "antd";
import { displayMessage, postAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import { CREATE_POPUP } from "../../constants/api";

// export default class Addcategories extends Component {
function AddPopup() {
  const { Content } = Layout;
  let history = useNavigate();

  // For Image Selection
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileValue, setSelectedFileValue] = useState(null);
  const imageExtensions = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
    "image/bmp",
  ];
  const onFileChange = (e) => {
    const files = e?.target?.files;
    let filePath = files[0];
    console.log(files);
    if (imageExtensions.includes(filePath?.type)) {
      setSelectedFile({ selectedFile: filePath });
      setSelectedFileValue(filePath.name);
    } else {
      setSelectedFile({});
      displayMessage(ERROR_MSG_TYPE, "not supported images");
    }
  };

  useLayoutEffect(() => {});

  const createSliderBanner = (values) => {
    console.log(values);

    const data = new FormData();
    data.append("link", values.link);
    if (selectedFile) {
      data.append("image", selectedFile.selectedFile);
    }
    const successFn = function (data) {
      history("/pop-up");
      displayMessage(SUCCESS_MESSAGE, "Data Saved Successfully");
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Save Data");
    };
    postAPI(CREATE_POPUP, data, successFn, errorFn);
  };
  return (
    <>
      <Layout>
        <LayoutHeader />
        <Content>
          <Card title="Add Pop-Up" bordered={false}>
            <Form onFinish={createSliderBanner}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="link"
                      // label="link"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Link"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      type="file"
                      name="image"
                      // label="Category Image"
                      rules={[{ required: true }]}
                      style={{ marginLeft: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type="file"
                        className="filetype"
                        defaultValue={selectedFileValue}
                        id="myImage"
                        onChange={(e) => onFileChange(e)}
                        name="myImage"
                        placeholder="Pop-Up Image"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>

              <Row spceber>
                <Col span={24}>
                  <Form.Item style={{ float: "right" }} label="" colon={false}>
                    <Button block htmlType="submit">
                      Submit Pop-Up
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Content>
        <LayoutFooter />
      </Layout>
    </>
  );
}
export default AddPopup;
