import React from "react";
import LayoutFooter from "../inc/LayoutFooter";
import LayoutHeader from "../inc/LayoutHeader";
import { Layout, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { Button, Card, Form, Input } from "antd";
import { displayMessage, postAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import { CREATE_CARRER_POSTS, PROJECT_NAME } from "../../constants/api";

function AddCarrerPosts() {
  const { Content } = Layout;
  let history = useNavigate();

  const createData = (values) => {
    console.log(values);

    const data = new FormData();

    data.append("title", values.title);
    data.append("job_type", values.job_type);
    data.append("job_location", values.job_location);
    data.append("salary", values.salary);
    data.append("salary_type", values.salary_type);
    data.append("company_name", values.company_name);
    data.append("total_position", values.total_position);
    const successFn = function (data) {
      console.log(data);
      if (data["status"] === true) {
        history("/carrer-posts");
        displayMessage(SUCCESS_MESSAGE, "Data Saved Successfully");
      } else {
        displayMessage(ERROR_MSG_TYPE, data["message"]);
      }
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Save Data");
    };
    postAPI(CREATE_CARRER_POSTS, data, successFn, errorFn);
  };
  return (
    <>
      <Layout>
        <LayoutHeader />
        <Content>
          <Card title="Add Carrer-Posts" bordered={false}>
            <Form onFinish={createData} layout="vertical">
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="title"
                      label="Title"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Title"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="job_type"
                      label="Job Type "
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Ex: Full-Time, Part-Time"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="job_location"
                      label="Job Location"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Job Location"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="salary"
                      label="salary"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Ex: 10K-20K"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="salary_type"
                      label="Salary Type"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Ex: Year or Month"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="company_name"
                      label="Company Name "
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder={"Ex: " + PROJECT_NAME}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row spceber>

                  <Col span={12}>
                    <Form.Item
                      name="total_position"
                      label="Total Position"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Ex: 1"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Row spceber>
                <Col span={24}>
                  <Form.Item style={{ float: "right" }} label="" colon={false}>
                    <Button block htmlType="submit">
                      Submit Carrer-Post
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Content>
        <LayoutFooter />
      </Layout>
    </>
  );
}
export default AddCarrerPosts;
