import React, { useState } from "react";
import LayoutFooter from "../inc/LayoutFooter";
import LayoutHeader from "../inc/LayoutHeader";
import { Layout, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { Button, Card, Form, Input } from "antd";
import { displayMessage, postAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import { CREATE_CATEGORY } from "../../constants/api";

// export default class Addcategories extends Component {
function AddCategories() {
  const { Content } = Layout;
  let history = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileValue, setSelectedFileValue] = useState(null);
  const imageExtensions = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
    "image/bmp",
  ];
  const onFileChange = (e) => {
    const files = e?.target?.files;
    let filePath = files[0];
    console.log(files);
    if (imageExtensions.includes(filePath?.type)) {
      setSelectedFile({ selectedFile: filePath });
      setSelectedFileValue(filePath.name);
    } else {
      setSelectedFile({});
      displayMessage(ERROR_MSG_TYPE, "not supported images");
    }
  };
  const createCategory = (values) => {
    console.log(values);

    const data = new FormData();
    data.append("title", values.category_name);
    data.append("description", values.category_description);
    data.append("slug", values.category_slug);
    if (selectedFile) {
      data.append("image", selectedFile.selectedFile);
    }
    const successFn = function (data) {
      history("/category");
      displayMessage(SUCCESS_MESSAGE, "Data Saved Successfully");
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Save Data");
    };
    postAPI(CREATE_CATEGORY, data, successFn, errorFn);
  };
  return (
    <>
      <Layout>
        <LayoutHeader />
        <Content>
          <Card title="Add Category" bordered={false}>
            <Form onFinish={createCategory}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="category_name"
                      // label="Category Name"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Category Name"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      type="file"
                      name="category_image"
                      // label="Category Image"
                      rules={[{ required: true }]}
                      style={{ marginLeft: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type="file"
                        className="filetype"
                        defaultValue={selectedFileValue}
                        id="myImage"
                        onChange={(e) => onFileChange(e)}
                        name="myImage"
                        placeholder="Category Image"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="category_description"
                      // label="Category Name"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Category Description"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="category_slug"
                      // label="Category Name"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Category Slug"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Row spceber>
                <Col span={24}>
                  <Form.Item style={{ float: "right" }} label="" colon={false}>
                    <Button block htmlType="submit">
                      Submit Category
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Content>
        <LayoutFooter />
      </Layout>
    </>
  );
}
export default AddCategories;

// import React from "react";

// import LayoutFooter from "../inc/LayoutFooter";
// import LayoutHeader from "../inc/LayoutHeader";
// import { Layout, Col, Row } from "antd";
// import { Button, Card, Form, Input } from "antd";
// import { displayMessage, postAPI } from "../../utils/common";
// import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
// import { CREATE_CATEGORY } from "../../constants/api";

// function AddCategory() {

//     const { Content } = Layout;

// const createCategory = (values) => {
//   console.log(values);
//   let data = {
//     name: values.category_name,
//     image: values.category_image,
//   };
//   const successFn = function (data) {
//     displayMessage(SUCCESS_MESSAGE, "Data Saved Successfully");
//   };
//   const errorFn = function () {
//     displayMessage(ERROR_MSG_TYPE, "Unable to Save Data");
//   };
//   postAPI(CREATE_CATEGORY, data, successFn, errorFn);
// };

//   return (
//     <>
//       <Layout>
//         <LayoutHeader />
//         <Content>
//           <Card title="Add Course Category" bordered={false}>
//             <Form onFinish={createCategory}>
//               <Form.Item style={{ marginBottom: 0 }}>
//                 <Row spceber>
//                   <Col span={12}>
//                     <Form.Item
//                       name="category_name"
//                       label="Category Name"
//                       rules={[{ required: true }]}
//                       style={{ marginRight: "5px" }}
//                     >
//                       <Input
//                         style={{ height: "35px" }}
//                         type={"text"}
//                         placeholder="Category Name"
//                       />
//                     </Form.Item>
//                   </Col>
//                   <Col span={12}>
//                     <Form.Item
//                       type="file"
//                       name="category_image"
//                       label="Category Image"
//                       rules={[{ required: true }]}
//                       style={{ marginLeft: "5px" }}
//                     >
//                       <Input
//                         style={{ height: "35px" }}
//                         type="file"
//                         onChange={this.onImageChange}
//                         className="filetype"
//                         id="group_image"
//                         placeholder="Category Image"
//                       />
//                     </Form.Item>
//                   </Col>
//                 </Row>
//               </Form.Item>
//               <Row spceber>
//                 <Col span={24}>
//                   <Form.Item style={{ float: "right" }} label="" colon={false}>
//                     <Button type="primary" block htmlType="submit">
//                       Submit Category
//                     </Button>
//                   </Form.Item>
//                 </Col>
//               </Row>
//             </Form>
//           </Card>
//         </Content>
//         <LayoutFooter />
//       </Layout>
//     </>
//   );
// }
// export default AddCategory;
