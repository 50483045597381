import "./App.css";
// import React, { useEffect } from "react";

import Base from "./Components/inc/Base";
import Login from "./Components/auth/login";
// import 'antd/dist/antd/antd.css';
// require("antd/dist/antd.min.css");
// import {  useNavigate } from "react-router-dom";

import "antd/dist/reset.css";

function App() {
  return (
    localStorage.getItem("id") ? <div className="App">
      <Base />
    </div> : <div className="App">
      <Login />
    </div>
  );
}

export default App;
