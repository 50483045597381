/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from "react";
import { displayMessage, postAPI, getAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import {
  ALL_TESTIMONIAL,
  DELETE_TESTIMONIAL,
  CLENT_USER_BASE_URL,
  CLENT_COMPANY_BASE_URL,
} from "../../constants/api";
import { Space, Button, Table } from "antd";
import { useNavigate } from "react-router-dom";
// import  { ColumnsType } from 'antd/es/table';
import LayoutHeader from "../inc/LayoutHeader";
import LayoutFooter from "../inc/LayoutFooter";
import { Layout } from "antd";
import { Card } from "antd";
import { Image } from "antd";

function TestimonialTabel() {
  const { Content } = Layout;
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(true);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (state.length === 0) {
      getCatgory();
    }
  }, [state]);
  const getCatgory = () => {
    setloading(true);
    const successFn = function (data) {
      console.log(data);

      if (data["status"] === true) {
        setstate(
          data["data"].map((row) => ({
            Name: row.name,
            CompanyName: row.company_name,
            UserImage: (
              <Image width={50} src={CLENT_USER_BASE_URL + row.user_image} />
            ),
            CompanyImage: (
              <Image
                width={50}
                src={CLENT_COMPANY_BASE_URL + row.company_logo}
              />
            ),
            ID: row.id,
            Action: (
              <Space>
                <Button
                  onClick={() => {
                    navigate("/testimonial/edit/" + row.id);
                  }}
                  block
                >
                  Edit
                </Button>
                <Button onClick={(e) => deleteCatgory(row.id)} danger>
                  Delete
                </Button>
              </Space>
            ),
          }))
        );
      } else {
        setstate([]);
      }
      setloading(false);
      console.log(state);
    };
    const errorFn = function () {
      setstate([]);
    };
    getAPI(ALL_TESTIMONIAL, successFn, errorFn, {});
  };
  const deleteCatgory = (values) => {
    console.log(values);
    let data = {
      id: values,
    };
    const successFn = function (data) {
      getCatgory();
      displayMessage(SUCCESS_MESSAGE, "Data Delete Successfully");
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Delete Data");
    };
    postAPI(DELETE_TESTIMONIAL, data, successFn, errorFn);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "ID",
      width: 50,
    },
    {
      title: "Name",
      dataIndex: "Name",
      width: 150,
    },
    {
      title: "Company Name",
      dataIndex: "CompanyName",
      width: 150,
    },
    {
      title: "User Image",
      dataIndex: "UserImage",
      width: 150,
    },
    {
      title: "Company Image",
      dataIndex: "CompanyImage",
      width: 150,
    },
    {
      title: "Action",
      dataIndex: "Action",
      width: 150,
    },
  ];
  return (
    <>
      <Layout>
        <LayoutHeader />
        <Content>
          <Card
            title="Manage Testimonial"
            extra={
              <Button
                onClick={() => {
                  navigate("/testimonial/add");
                }}
                block
              >
                Add Testimonial
              </Button>
            }
            bordered={true}
          >
            <Table
              loading={loading}
              columns={columns}
              dataSource={state}
              pagination={{ pageSize: 10 }}
              // scroll={{ y: 240 }}
            />
          </Card>
        </Content>
        <LayoutFooter />
      </Layout>
    </>
  );
}
export default TestimonialTabel;
