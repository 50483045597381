/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from "react";
import LayoutFooter from "../inc/LayoutFooter";
import LayoutHeader from "../inc/LayoutHeader";
import { useParams, useNavigate } from "react-router-dom";
import { Layout, Col, Row } from "antd";
import { Button, Card, Form, Input } from "antd";
import { displayMessage, postAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import {
  EDIT_CATEGORY,
  CATEGORY_BY_SLUG,
  CATEGORY_BASE_URL,
} from "../../constants/api";
import { Image } from "antd";

function EditCategory() {
  const { Content } = Layout;
  const { slug } = useParams();
  let history = useNavigate();

  const [state, setstate] = useState(null);
  const [loading, setloading] = useState(true);
  const [form] = Form.useForm();
  const [oldImage, setOldImage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileValue, setSelectedFileValue] = useState(null);
  const imageExtensions = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
    "image/bmp",
  ];

  useLayoutEffect(() => {
    if (state === null) {
      getDetail();
    }
  }, [
    state,
    form.setFieldsValue({
      category_name: state != null ? state["title"] : "",
      category_slug: state != null ? state["slug"] : "",
      category_description: state != null ? state["description"] : "",
    }),
  ]);

  const getDetail = () => {
    console.log("Navigating to editCategory/" + slug);
    const postData = new FormData();
    postData.append("slug", slug);
    const successFn = function (data) {
      console.log(data);
      setstate(data["data"]);
      setOldImage(data["status"] !== false ? data["data"]["image"] : "");
      setloading(false);
      console.log(state);
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to get detail");
    };
    postAPI(CATEGORY_BY_SLUG, postData, successFn, errorFn);
  };

  const onFileChange = (e) => {
    const files = e?.target?.files;
    let filePath = files[0];
    console.log(files);
    if (imageExtensions.includes(filePath?.type)) {
      setSelectedFile({ selectedFile: filePath });
      setSelectedFileValue(filePath.name);
    } else {
      setSelectedFile({});
      displayMessage(ERROR_MSG_TYPE, "not supported images");
    }
  };
  const updateCategory = (values) => {
    console.log(values);

    const data = new FormData();
    data.append("id", state["id"]);
    data.append("title", values.category_name);
    data.append("description", values.category_description);
    data.append("slug", values.category_slug);
    if (selectedFile) {
      data.append("image", selectedFile.selectedFile);
    }
    const successFn = function (data) {
      history("/category");
      displayMessage(SUCCESS_MESSAGE, "Data Updated Successfully");
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Update Data");
    };
    postAPI(EDIT_CATEGORY, data, successFn, errorFn);
  };
  return (
    <>
      <Layout>
        <LayoutHeader />
        <Content>
          <Card loading={loading} title="Edit Category" bordered={false}>
            <Form onFinish={updateCategory} form={form}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="category_name"
                      // label="Category Name"
                      rules={[{ required: false }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Category Name"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      type="file"
                      name="category_image"
                      // label="Category Image"
                      rules={[{ required: false }]}
                      style={{ marginLeft: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type="file"
                        className="filetype"
                        defaultValue={selectedFileValue}
                        id="myImage"
                        onChange={(e) => onFileChange(e)}
                        name="myImage"
                        placeholder="Category Image"
                      />
                      <hr />
                      <Image width={100} src={CATEGORY_BASE_URL + oldImage} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="category_description"
                      // label="Category Name"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Category Description"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="category_slug"
                      // label="Category Name"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Category Slug"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Row>
                <Col span={24}>
                  <Form.Item style={{ float: "right" }} label="" colon={false}>
                    <Button block htmlType="submit">
                      Update Category
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Content>
        <LayoutFooter />
      </Layout>
    </>
  );
}
export default EditCategory;
