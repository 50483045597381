// Backend Base Url
export const BACKEND_BASE_URL = "https://backend.awdigitalsupport.com";
// export const BACKEND_BASE_URL = "http://192.168.1.6:8000";
// -----------------------
// MediaBase Url
export const IMAGE_BASE_URL = BACKEND_BASE_URL + "/images/";
export const IMAGE_SLIDER_URL = BACKEND_BASE_URL + "/slider/";
export const IMAGE_POPUP_URL = BACKEND_BASE_URL + "/popup/";
export const CATEGORY_BASE_URL = BACKEND_BASE_URL + "/category/";
export const CLENT_USER_BASE_URL = BACKEND_BASE_URL + "/client/userImage/";
export const CLENT_COMPANY_BASE_URL =
  BACKEND_BASE_URL + "/client/companyImage/";
export const BLOG_BASE_URL = BACKEND_BASE_URL + "/blog/";
export const RESUME_BASE_URL = BACKEND_BASE_URL + "/resume/";
// -----------------------
// SubPath
export const API_URL = BACKEND_BASE_URL + "/api";

// Auth API
export const LOGIN = "login";
export const LOGOUT = "logout";
export const AUTH_DETAIL = "user";

// User API
export const USER = "user/list";
export const USER_DETAIL = "user/detailById";
export const ADD_USER = "user/add";
export const EDIT_USER = "user/update";
export const DELETE_USER = "user/delete";

// Contacts Message
export const ALL_CONTACTS = "contact";

// Clent/Testimonial API
export const ALL_TESTIMONIAL = "client";
export const TESTIMONIAL_DETAIL = "clientById";
export const CREATE_TESTIMONIAL = "client/add";
export const EDIT_TESTIMONIAL = "client/update";
export const DELETE_TESTIMONIAL = "client/delete";

// Category API
export const ALL_CATEGORY = "category";
export const CATEGORY_BY_SLUG = "categoryBySlug";
export const CREATE_CATEGORY = "category/add";
export const EDIT_CATEGORY = "category/update";
export const DELETE_CATEGORY = "category/delete";

// Setting API
export const CREATE_SETTING = "createSetting";
export const SETTING = "settings";

// Slider-Banner  API
export const ALL_SLIDERBANNER = "slider";
export const CREATE_SLIDERBANNER = "slider/add";
export const EDIT_SLIDERBANNER = "slider/update";
export const DELETE_SLIDERBANNER = "slider/delete";

// Pop-Up  API
export const ALL_POPUP = "popup";
export const CREATE_POPUP = "popup/add";
export const EDIT_POPUP = "popup/update";
export const DELETE_POPUP = "popup/delete";

// Carrer-Posts  API
export const ALL_CARRER_POSTS = "carrerPosts";
export const CARRER_POST_DETAIL = "carrerPostsByID";
export const CREATE_CARRER_POSTS = "carrerPosts/add";
export const EDIT_CARRER_POSTS = "carrerPosts/update";
export const DELETE_CARRER_POSTS = "carrerPosts/delete";

// Carrer-Applieds  API
export const ALL_CARRER_APPLIEDS = "carrerApplied";
export const CREATE_CARRER_APPLIEDS = "carrerApplied/add";
export const EDIT_CARRER_APPLIEDS = "carrerApplied/update";
export const DELETE_CARRER_APPLIEDS = "carrerApplied/delete";

// Dynamic Content  API
export const ALL_DYNAMIC_CONTENT = "blog/";
export const DYNAMIC_CONTENT_BY_SLUG = "blogBySlug";
export const CREATE_DYNAMIC_CONTENT = "blog/add";
export const EDIT_DYNAMIC_CONTENT = "blog/update";
export const DELETE_DYNAMIC_CONTENT = "blog/delete";

// Notification Content  API
export const ALL_NOTIFICATION = "notification";
export const CREATE_NOTIFICATION = "createNotification";
export const EDIT_NOTIFICATION = "updateNotification";
export const DELETE_NOTIFICATION = "deleteNotification";

// ==================== CONST DATA START ==================== //

// Project Name
export const PROJECT_NAME = "AW Digital Support";

// ==================== CONST DATA END ==================== //
