import React from "react";
import LayoutFooter from "../inc/LayoutFooter";
import LayoutHeader from "../inc/LayoutHeader";
import { Layout, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { Button, Card, Form, Input } from "antd";
import { displayMessage, postAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import { ADD_USER } from "../../constants/api";

function AddUser() {
  const { Content } = Layout;
  let history = useNavigate();

  const createData = (values) => {
    console.log(values);

    const data = new FormData();
    data.append("name", values.name);
    data.append("email", values.email);
    data.append("password", values.password);

    const successFn = function (data) {
      console.log(data);
      if (data["status"] === true) {
        history("/user");
        displayMessage(SUCCESS_MESSAGE, "Data Saved Successfully");
      } else {
        displayMessage(ERROR_MSG_TYPE, data["message"]);
      }
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Save Data");
    };
    postAPI(ADD_USER, data, successFn, errorFn);
  };
  return (
    <>
      <Layout>
        <LayoutHeader />
        <Content>
          <Card title="Add User" bordered={false}>
            <Form onFinish={createData} layout="vertical">
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="name"
                      label="Full Name"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Full Name"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="email"
                      label="Email "
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Email"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row spceber>
                  <Col span={24}>
                    <Form.Item
                      name="password"
                      label="Password "
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Password"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Row spceber>
                <Col span={24}>
                  <Form.Item style={{ float: "right" }} label="" colon={false}>
                    <Button block htmlType="submit">
                      Submit User
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Content>
        <LayoutFooter />
      </Layout>
    </>
  );
}
export default AddUser;
