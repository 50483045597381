import React from "react";
import { Layout } from "antd";
import { Button, Form, Input, Card, Divider, Col } from "antd";
import { displayMessage, postAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import { LOGIN } from "../../constants/api";

function Login() {
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    console.log(values);

    const data = new FormData();
    data.append("email", values.email);
    data.append("password", values.password);
    data.append("roleType", "Admin");
    const successFn = function (data) {
      console.log(data);

      if (data["status"] === true) {
        localStorage.setItem("id", data["user"]["id"]);
        localStorage.setItem("token", data["token"]);
        localStorage.setItem("name", data["user"]["name"]);
        localStorage.setItem("email", data["user"]["email"]);
        displayMessage(SUCCESS_MESSAGE, data["message"]);
        window.location.reload();
      } else {
        displayMessage(ERROR_MSG_TYPE, data["message"]);
      }
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Save Data");
    };
    postAPI(LOGIN, data, successFn, errorFn);
  };
  return (
    <>
      <Layout className="login-layout">
        <Col
          xs={{ span: 20, offset: 2 }}
          sm={{ span: 16, offset: 4 }}
          md={{ span: 12, offset: 6 }}
          lg={{ span: 8, offset: 8 }}
          xl={{ span: 8, offset: 8 }}
        >
          <Card style={{ background: "white", borderRadius: "10px" }}>
            <img className="logo" src="./logo.png" alt="Logo" />

            <Form
              layout="vertical"
              style={{ textAligns: "center" }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Email-ID"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Divider />
              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  className="shButton"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Layout>
    </>
  );
}
export default Login;
