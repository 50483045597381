/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from "react";
import { Layout } from "antd";
import LayoutHeader from "../inc/LayoutHeader";
import LayoutFooter from "../inc/LayoutFooter";
import { Typography, Card, Table } from "antd";
import { PROJECT_NAME, ALL_CONTACTS } from "../../constants/api";
import { useNavigate } from "react-router-dom";
import { displayMessage, getAPI } from "../../utils/common";
import { ERROR_MSG_TYPE } from "../../constants/messages";

function Dashboard() {
  const { Content } = Layout;
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(true);

  const date = new Date();
  const currentTime = date.getHours();

  let greeting;

  if (currentTime >= 0 && currentTime <= 12) {
    greeting = "Good Morning";
  } else if (currentTime > 12 && currentTime <= 18) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }

  useLayoutEffect(() => {
    if (state.length === 0) {
      getUser();
    }
  }, [state, navigate]);
  const getUser = () => {
    const successFn = function (data) {
      console.log(data);
      if (data["status"] === true) {
        setloading(false);
        setstate(
          data["data"].map((row) => ({
            name: row.name,
            email: row.email,
            phone: row.phone,
            whatsapp_number: row.whatsapp_number,
            subject: row.subject,
            interested_in: row.interested_in,
            message: row.message,
            date: row.created_at,
          }))
        );
        console.log(state);
      } else {
        displayMessage(ERROR_MSG_TYPE, data["message"]);
      }
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Get Data");
    };
    getAPI(ALL_CONTACTS, successFn, errorFn, {});
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 50,
    },

    {
      title: "Phone",
      dataIndex: "phone",
      width: 150,
    },
    {
      title: "Whatsapp Number",
      dataIndex: "whatsapp_number",
      width: 150,
    },
    {
      title: "Interested-In",
      dataIndex: "interested_in",
      width: 150,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      width: 150,
    },
    {
      title: "Message",
      dataIndex: "message",
      width: 150,
    },
    {
      title: "Date",
      dataIndex: "date",
      width: 150,
    },
  ];

  return (
    <Layout>
      <LayoutHeader />
      <Content>
        <Card
          key={1}
          style={{
            // borderRadius: "10px",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            // background: `url(/grass.png)`,
            objectFit: "fill",
            backgroundRepeat: "no-repeat",
            // filter: "opacity(30%)",
            backgroundColor:
              greeting === "Good Morning"
                ? "azure"
                : greeting === "Good Afternoon"
                ? "rgb(255 137 14 / 75%)"
                : "#000000c9",

            backgroundSize: "cover",
            cursor: "pointer",
          }}
        >
          <Title
            key={2}
            level={5}
            style={{
              color:
                greeting === "Good Morning"
                  ? "black"
                  : greeting === "Good Afternoon"
                  ? "white"
                  : "white",
            }}
          >
            {greeting}, {localStorage.getItem("name")}
          </Title>
          <Text
            key={3}
            style={{
              color:
                greeting === "Good Morning"
                  ? "black"
                  : greeting === "Good Afternoon"
                  ? "white"
                  : "white",
            }}
          >
            Welcome to {PROJECT_NAME}
          </Text>
        </Card>
        <div style={{ marginTop: "10px" }}>
          <Table
            loading={loading ? true : false}
            columns={columns}
            dataSource={state}
            pagination={{ pageSize: 10 }}
            // scroll={{ y: 240 }}
          />
        </div>
      </Content>
      <LayoutFooter />
    </Layout>
  );
}
export default Dashboard;
