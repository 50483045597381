import React, { useLayoutEffect, useState } from "react";
import LayoutFooter from "../inc/LayoutFooter";
import LayoutHeader from "../inc/LayoutHeader";
import { useNavigate } from "react-router-dom";
import { Layout, Col, Row } from "antd";
import { Card, Form, Select, Input } from "antd";
import { displayMessage, postAPI, getAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import { CREATE_DYNAMIC_CONTENT, ALL_CATEGORY } from "../../constants/api";
import JoditEditor from "jodit-react";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

function AddDynamicContent() {
  const { Content } = Layout;
  let history = useNavigate();
  const [category, setCategory] = useState([]);
  const [loading, setloading] = useState(true);
  useLayoutEffect(() => {
    if (category.length === 0) {
      getCatgory();
    }
  }, [category]);
  const getCatgory = () => {
    setloading(true);
    const successFn = function (data) {
      console.log(data);

      if (data["status"] === true) {
        setCategory(data["data"]);
        setloading(false);
      } else {
        setCategory([]);
        setloading(false);
      }
    };
    const errorFn = function () {
      setCategory([]);
    };
    getAPI(ALL_CATEGORY, successFn, errorFn, {});
  };

  const createOnBording = (values) => {
    console.log(values);

    const data = new FormData();
    data.append("category_id", values.category_id);
    data.append("meta_title", values.meta_title);
    data.append("meta_description", values.meta_description);
    data.append("meta_keyword", values.meta_keyword);
    data.append("title", values.title);
    data.append("description", values.description);
    data.append("slug", values.slug);
    data.append("isBlog", values.product_type);
    // Add selected images to the FormData object
    values.images.forEach((image) => {
      data.append("images[]", image.originFileObj);
    });

    const successFn = function (data) {
      console.log(data);
      history("/dynamicContent");
      displayMessage(SUCCESS_MESSAGE, "Data Saved Successfully");
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Save Data");
    };
    postAPI(CREATE_DYNAMIC_CONTENT, data, successFn, errorFn);
  };
  return (
    <>
      <Layout>
        <LayoutHeader />
        <Content>
          <Card loading={loading} title="Add Dynamic Content" bordered={false}>
            <Form onFinish={createOnBording} layout="vertical">
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="category_id"
                      label="Category"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Select placeholder="Select Content For">
                        {category.map((row) => (
                          <Select.Option key={row.id} value={row.id}>
                            {row.title}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="title"
                      label="Title"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Title"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="meta_title"
                      label="Meta Title"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Meta Title"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="meta_keyword"
                      label="Meta Keyword"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Meta Keyword"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="meta_description"
                      label="Meta Description"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Meta Description"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="slug"
                      label="Slug"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Input
                        style={{ height: "35px" }}
                        type={"text"}
                        placeholder="Slug"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Row spceber>
                  <Col span={12}>
                    <Form.Item
                      name="product_type"
                      label="Product Type"
                      rules={[{ required: true }]}
                      style={{ marginRight: "5px" }}
                    >
                      <Select placeholder="Select Content Is">
                        <Select.Option value={"1"}>Work</Select.Option>
                        <Select.Option value={"0"}>Blog</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Select Images"
                      name="images"
                      style={{ marginRight: "5px" }}
                      valuePropName="fileList"
                      getValueFromEvent={(e) => {
                        if (Array.isArray(e)) {
                          return e;
                        }
                        return e && e.fileList;
                      }}
                      initialValue={[]}
                      rules={[
                        {
                          required: true,
                          message: "Please select at least one image",
                          type: "array",
                        },
                      ]}
                    >
                      <Upload
                        name="myImage"
                        listType="picture"
                        multiple={true}
                        beforeUpload={() => false} // Prevent file upload immediately
                      >
                        <Button icon={<UploadOutlined />}>Select Images</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>

              <Row spceber>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true }]}
                    style={{ marginRight: "5px" }}
                  >
                    <JoditEditor placeholder="Just brief the your product" />
                  </Form.Item>
                </Col>
              </Row>

              <Row spceber>
                <Col span={24}>
                  <Form.Item style={{ float: "right" }} label="" colon={false}>
                    <Button block htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Content>
        <LayoutFooter />
      </Layout>
    </>
  );
}
export default AddDynamicContent;
