import React, { useLayoutEffect } from "react";
import { Layout, Dropdown, Button, Form, Menu } from "antd";
import { displayMessage, postAPI, getAPI } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../constants/messages";
import { LogoutOutlined } from "@ant-design/icons";
import { LOGOUT, AUTH_DETAIL } from "../../constants/api";

function LayoutHeader() {
  const { Header } = Layout;

  useLayoutEffect(() => {
    getAuthDetail();
  }, []);
  const getAuthDetail = () => {
    const successFn = function (data) {
      if (data["status"] === false) {
        console.log(data);
        localStorage.clear();
        window.location.reload();
        displayMessage(SUCCESS_MESSAGE, "You are login in another device.");
      }
    };
    const errorFn = function () {
      localStorage.clear();
      window.location.reload();
      displayMessage(SUCCESS_MESSAGE, "You are login in another device.");
    };
    getAPI(AUTH_DETAIL, successFn, errorFn, {});
  };

  const logout = (values) => {
    const data = new FormData();
    const successFn = function (data) {
      console.log(data);
      if (data["status"] === true) {
        localStorage.clear();
        window.location.reload();
        displayMessage(SUCCESS_MESSAGE, "Logout Successfully");
      } else {
        displayMessage(ERROR_MSG_TYPE, data["message"]);
      }
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Logout");
    };
    postAPI(LOGOUT, data, successFn, errorFn);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Form onFinish={logout}>
          <Menu.Item key="Dashboard" icon={<LogoutOutlined />}>
            <Button className="shButton" htmlType="submit">
              Logout
            </Button>
          </Menu.Item>
        </Form>
      ),
    },
  ];
  return (
    <Header className="site-layout-sub-header-background">
      <Dropdown menu={{ items }} placement="bottom">
        <img
          className="profile-square"
          src="./user_placeholder.png"
          alt="Logo"
        />
      </Dropdown>
    </Header>
  );
}
// }

export default LayoutHeader;
