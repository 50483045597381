import { Layout } from "antd";

import { Route, Routes } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";

import WebsiteSetting from "../website-setting/WebsiteSettings";

import Login from "../auth/login";
import SiderBar from "../inc/SiderBar";

import CategoryTabel from "../category/Categorytabel";
import CreateCategory from "../category/Addcategories";
import EditCategory from "../category/EditCategory";

import TestimonialTabel from "../testimonial/TestimonialTabel";
import CreateTestimonial from "../testimonial/AddTestimonial";
import EditTestimonial from "../testimonial/EditTestimonial";

import UserTable from "../user/UserTable";
import AddUser from "../user/AddUser";
import EditUser from "../user/EditUser";

import SliderBannerTabel from "../slider-banner/SliderBannerTabel";
import AddSliderBanner from "../slider-banner/AddSliderBanner";
import EditSliderBanner from "../slider-banner/EditSliderBanner";

import PopupTabel from "../pop-up/PopupTabel";
import AddPopup from "../pop-up/AddPopup";

import CarrerPostsTable from "../carrer-posts/CarrerPostsTable";
import AddCarrerPosts from "../carrer-posts/AddCarrerPosts";
import EditCarrerPosts from "../carrer-posts/EditCarrerPosts";

import DynamicContentTabel from "../dynamicContent/DynamicContentTabel";
import AddDynamicContent from "../dynamicContent/AddDynamicContent";
import EditDynamicContent from "../dynamicContent/EditDynamicContent";

import CarrerAppliedTable from "../carrer-applied/CarrerAppliedTable";

import ContactsTable from "../contacts-message/ContactsTable";
// import WishlistDetail from "../wishlist/WishlistDetail";

const Base = () => {
  return (
    <Layout>
      <SiderBar />

      {/* Routes */}

      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Dashboard />} />

        <Route path="/category" element={<CategoryTabel />} />
        <Route path="/category/add" element={<CreateCategory />} />
        <Route path="/category/edit/:slug" element={<EditCategory />} />

        <Route path="/testimonial" element={<TestimonialTabel />} />
        <Route path="/testimonial/add" element={<CreateTestimonial />} />
        <Route path="/testimonial/edit/:id" element={<EditTestimonial />} />

        <Route path="/user" element={<UserTable />} />
        <Route path="/user/add" element={<AddUser />} />
        <Route path="/user/edit/:id" element={<EditUser />} />

        <Route path="/slider-banner" element={<SliderBannerTabel />} />
        <Route path="/slider-banner/add" element={<AddSliderBanner />} />
        <Route path="/slider-banner/edit" element={<EditSliderBanner />} />

        <Route path="/pop-up" element={<PopupTabel />} />
        <Route path="/pop-up/add" element={<AddPopup />} />

        <Route path="/carrer-posts" element={<CarrerPostsTable />} />
        <Route path="/carrer-posts/add" element={<AddCarrerPosts />} />
        <Route path="/carrer-posts/edit/:id" element={<EditCarrerPosts />} />

        <Route path="/dynamic-content" element={<DynamicContentTabel />} />
        <Route path="/dynamic-content/add" element={<AddDynamicContent />} />
        <Route
          path="/dynamic-content/edit/:slug"
          element={<EditDynamicContent />}
        />

        <Route path="/carrer-applieds" element={<CarrerAppliedTable />} />

        <Route path="/contacts-message" element={<ContactsTable />} />

        <Route path="/websiteSetting" element={<WebsiteSetting />} />
      </Routes>
    </Layout>
  );
};

export default Base;
